<template>
  <RoleForm v-if="role" :role="role" :isEdit="true"/>
</template>

<script>

import AuthorizationService from "@/modules/Admin/Services/AuthorizationService";
import store from "@/modules/Shared/Store/store";
import RoleForm from "@/modules/Admin/Components/RoleForm.vue";

export default {
  name: "EditRolePage",
  components: {RoleForm},

  data() {
    return {
      role: null,
    }
  },
  async beforeRouteEnter (to, from, next) {
      const role = await AuthorizationService.getRole(to.params.id);
      await store.dispatch('setRouteElement', role)
      next(vm => vm.role = role)
  },
};
</script>
